import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import livecasino from '../images/casino/banner_casino-half.webp'
import roulletee from '../images/casino/ezugi.webp'
import venuscasino from '../images/casino/venus.webp'
import Colorgame from '../images/casino/colorgame.jpeg'
import card32 from '../images/kv/32card-half.png'
import teenipatti from '../images/casino/teenpatti20.webp';
import cockfight from '../images/casino/cockfight.webp'
import evolutioncasino from '../images/casino/banner_evo-half.webp'
import updown from '../images/casino/7up7down.jpeg'
import jetx3 from '../images/casino/banner_jetx.png'
import sportse from '../images/casino/sportse.webp'
import anderbahar from '../images/casino/andarbahar.webp'
import CoinToss from '../images/kv/CoinToss.png'
import blackjack from '../images/casino/Blackjack.webp'
import numbermatka from '../images/kv/numberMatka.png'
import aviatrixCasino from '../images/casino/aviatrix-casino.png'
import redtigercasino from '../images/casino/REDTIGER.webp'
import playtechcasino from '../images/casino/PLAYTECH.webp'
import Sports from '../images/Banner/bannersports.webp'
import cardmatka from '../images/kv/cardMatka.png'
import mineswiper from '../images/casino/mineswiper.jpeg'
import aviator from '../images/casino/banner_sbe_aviator.png'
import cricketx from '../images/casino/banner_cricketx.png'
import jetx from '../images/casino/banner_Aviator-jetx.png'
import { Link } from 'react-router-dom';
import Support from './Support';
import sibco from '../images/casino/sicbokm.jpeg'
import SABA from '../images/casino/saba-sport.webp'
import KingMakers from '../images/casino/lobby_kingmakers.ad101fd6.webp'
import ludogame from '../images/casino/LUDO.webp'
import aeSexyImage from '../images/casino/aesexy.webp'
import roulette from '../images/casino/europianroullete.webp'
import cardhilo from '../images/casino/cardhillo.webp'
import NumberKing from '../images/casino/numberking.webp'
import JilI from '../images/casino/banner_JILI-full.webp'
import BigSmall from '../images/casino/big-small.webp'
import Teenpattijoker from '../images/casino/teenpatti.jpeg'
import updownseven from '../images/casino/7updown.webp'
import DragerTiger from '../images/casino/dragon-tiger.jpeg'
import CallBreak from '../images/casino/callbreak.webp'
import SicboJili from '../images/casino/sicbo.webp'
import BaccaratJili from '../images/casino/baccaret.webp'
import horserace from '../images/Banner/horse-race.webp'
import pragmatics from '../images/casino/pp.jpeg'

import Cookies from 'universal-cookie';
import { getCasinoObj } from '../casino-utils';
import queryString from 'query-string';
import { requirePropFactory } from "@material-ui/core";
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import CasinoCard from "./components/casino-card/casino-card";


const cookies = new Cookies();
export default function Home(props) {

    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [countBasketBall, setCountBasketBall] = useState(0);
    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [casinoWait, setcasinoWait] = useState(false);

    const history = useHistory();


    function changecount(cri, socc, tenn, basket) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
        setCountBasketBall(basket)
    }


    useEffect(() => {
        axios.all([
            axios.get('https://liveapi247.live/api8/cricketData'),
            axios.get('https://liveapi247.live/api8/soccerData'),
            axios.get('https://liveapi247.live/api8/tennisData'),
            axios.get('https://liveapi247.live/api8/basketballData')
        ])
            .then(axios.spread((obj1, obj2, obj3, obj4) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;
                var count4 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj4.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                obj4.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count4 = count4 + 1;
                    }
                });
                setrefresh(false);
                setTimeout(() => { setloading(false); }, 5000);
                changecount(count1, count2, count3, count4);



            }));
    }, [])

    // const handleRefresh = () => {
    //     setloading(true);
    // }

    return (
        <React.Fragment>
            <LoadingOverlay
                show={(props.casinoWait || refresh === true)}
            />

            <div className="mob-main-content">
                <div className="casinozone">
                    <Link to="/exchange/displaymatch/inplay" style={{ gridColumn: 'span 2' }}>
                        <dl className="casino-title">
                            <dt>Sports</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <dl id="onLiveBoard" className="live_events">
                            <dt>
                                <p className="live_icon"><span></span> LIVE</p>
                            </dt>

                            <dd id="onLiveCount_CRICKET"><p>Cricket</p><span id="count">{countCricket}</span></dd>

                            <dd id="onLiveCount_SOCCER"><p>Soccer</p><span id="count">{countSoccer}</span></dd>

                            <dd id="onLiveCount_TENNIS"><p>Tennis</p><span id="count">{countTennis}</span></dd>
                         

                        </dl>
                        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/1.jpg" alt="" draggable="false" />
                    </Link>
                    {/* <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        title="Ezugi Casino"
                        img={roulletee}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    /> */}
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Aviator"
                        title="Aviator"
                        img={aviator}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="JetX"
                        title="Jetx"
                        img={jetx}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="JetX3"
                        title="Jetx3"
                        img={jetx3}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="CricketX"
                        title="Cricketx"
                        img={cricketx}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Aviatrix"
                        title="Aviatrix"
                        img={aviatrixCasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
   <CasinoCard
                      className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Sexy"
                        title="Sexy"
                        img={aeSexyImage}
                        openLogin={props.openLogin}
                        style={{}}
                        history={history}
                    />
                    <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="SABA"
                        title="Saba"
                        img={SABA}
                        openLogin={props.openLogin}
                        history={history}
                    />
                     <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Aviatrix"
                        title="Aviatrix"
                        img={playtechcasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                       <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="EVOLUTION"
                        title="Evolution"
                        img={evolutioncasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                     {/* <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Red Tiger"
                        title="Red Tiger"
                        img={redtigercasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    /> */}
                 

                 
                    {/* <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="LUDO"
                        title="Ludo"
                        img={ludogame}
                        openLogin={props.openLogin}
                        history={history}
                    /> */}
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Live Casino"
                        title="Live Casino"
                        img={livecasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="VENUS"
                        title="Venus"
                        img={venuscasino}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                  
                    <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="KINGMIDAS"
                        title="KINGMIDAS"
                        img={KingMakers}
                        openLogin={props.openLogin}
                        history={history}
                    />
                    <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="JILI"
                        title="Jili"
                        img={JilI}
                        openLogin={props.openLogin}
                        history={history}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="sicbo"
                        title="SicBo"
                        img={sibco}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="7 Up 7 Down"
                        title="7 up 7 down"
                        img={updown}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Coin Toss"
                        title="Coin Toss"
                        img={CoinToss}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="NumberMatka"
                        title="Number Matka"
                        img={numbermatka}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="CardMatka"
                        title="Card Matka"
                        img={cardmatka}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Andar Bahar"
                        title="Andar Bahar"
                        img={anderbahar}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Blackjack"
                        title="Blackjack"
                        img={blackjack}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                  

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="pragmatics play"
                        title="Pregmatics Play"
                        img={pragmatics}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Cards Hi Lo"
                        title="Card Hilo"
                        img={cardhilo}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Minesweeper"
                        title="Mine Sweeper"
                        img={mineswiper}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Teen Patti"
                        title="TeenPatti"
                        img={teenipatti}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />


                    {/* <!-- BetGames --> */}
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Number King"
                        title="Number King"
                        img={NumberKing}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Big Small"
                        title="Big Small"
                        img={BigSmall}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="TeenPatti Joker"
                        title="Teenpatti Joker"
                        img={Teenpattijoker}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="7up7down"
                        title="7Up7Down"
                        img={updownseven}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Dragon Tiger"
                        title="Dragon & Tiger"
                        img={DragerTiger}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Callbreak Quick"
                        title="Callbreak Quick"
                        img={CallBreak}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Sic Bo"
                        title="Sic Bo"
                        img={SicboJili}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Baccarat"
                        title="Bacarrat"
                        img={BaccaratJili}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="32 Cards"
                        title="32 Cards"
                        img={card32}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />

                    <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="Colour Game"
                        title="Color Game"
                        img={Colorgame}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
                    {/* <CasinoCard
                        className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="European Roulette"
                        title="european roulette"
                        img={roulette}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    /> */}
                      <CasinoCard
                      className="mob-casino-half"
                        isLoggedIn={props.isLoggedIn}
                        navKey="E1SPORT"
                        title="E1SPORT"
                        img={sportse}
                        openLogin={props.openLogin}
                        history={history}
                        style={{}}
                    />
{/* 
                    <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="HORSE BOOK"
                        title="Horse Racing"
                        img={horserace}
                        openLogin={props.openLogin}
                        history={history}
                    /> */}

                    {/* <CasinoCard
                        isLoggedIn={props.isLoggedIn}
                        navKey="Cock Fight PHI"
                        title="Cockfight"
                        img={cockfight}
                        openLogin={props.openLogin}
                        history={history}
                    /> */}


                </div>

                <Support handlepolicy={props.handlepolicy} />
            </div>

        </React.Fragment>
    )
}
