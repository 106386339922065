import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
require('moment-timezone');

export default function SoccerMatches(props) {


  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [ScoreData, setScoreData] = useState([])
    const [cricketUpdate, setcricketUpdate] = useState(false);

    var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";

  // vvvv

  useEffect(() => {

    Axios.get(
      `https://liveapi247.live/api8/soccerData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }
      })


      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api8/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));


    Axios.get(
      `https://liveapi247.live/api8/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);


    useEffect(() => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");


        if (props.isLoggedIn == true) {
            for (var i = 0; i < match.length; i++) {

                if (!document.getElementById(MKPINSC + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(match[i].eventId)) {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-on"
                }
                else {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off";
                }
            }
        }
        else {
            for (var i = 0; i < match.length; i++) {

                if (!document.getElementById(MKPINSC + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(match[i].eventId)) {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off"
                }
                else {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off";
                }
            }

        }


    }, [match, cricketUpdate]);

    const Multimarketpin = (index, event_id) => {
        if (props.isLoggedIn === false) {
            props.checkShowLogin(true)
        }
        else {
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
            let ctr = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i] == event_id) {
                    let j = i + 1;
                    ctr = 1;
                    for (; i < list.length; j++, i++) {
                        list[i] = list[j];
                    }
                    list.splice([i - 1]);
                }
            }
            if (ctr == 0) {
                list.push(event_id);
            }
            localStorage.setItem("multi", JSON.stringify(list));
            setcricketUpdate(!cricketUpdate);

            // console.log('2', list);

        }
    }

  // console.log('Soccer');
  return (

    <React.Fragment>

{newrefresh === true &&	<div class="loading-overlay" style={{display: 'flex'}} id="loader__">
	<div class="loading-wrap" style={{display: 'flex'}}>
		<div class="loading">
			<div></div>
			<div></div>
		</div>
		<p>Loading...</p>
	</div>
</div>}

      {newrefresh === false &&<>
        <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/1.png?v=1"/></div>

        {match.map(function (item, id) {
          let customScore = '';
          let elapsTime = '';
          let filteredData = ScoreData &&
            ScoreData.filter(x => String(x.eventId) === String(item.eventId));
          // let filteredData = [];
          if (filteredData && filteredData.length > 0) {
            customScore = filteredData[0].score;
            elapsTime = filteredData[0].timeElapsed
          }
          var showtv = false;
          var isbookmaker = false;

          var showColor = false;

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (tv.includes(item.eventId)) {
            showtv = true;
          }
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          }

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }

          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showColor = true;
          }


          return (
              <li
                  id="highlightEvent_33551880"
                  style={{ display: 'flex'}}
                  className={`event_list event_1 ${showColor ? "inplay-on" : "inplay-off"}`}>
                  <Link to={`/exchange/displaymatch/fullmarket/1/${item.eventId}/${item.marketId}`} onClick={() => {
                      props.changeState(true);
                  }}>
                      {moment() < moment(item.marketStartTime) &&
                          <img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/1_game-icon.png"/>}
                      {moment() >= moment(item.marketStartTime) &&
                          <img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/4_game-icon.png"/>}
                      <dl>
                          <dt style={{display: "flex"}}>
                              {moment() >= moment(item.marketStartTime) && <span
                                  id="streamingIcon"
                                  className="game-live"
                                  style={{display: "none"}}
                              >
                    game-live
                  </span>}
                              <span
                                  id="lowLiquidityTag"
                                  className="game-low_liq"
                                  style={{display: "none"}}
                              >
                    Low Liquidity
                  </span>
                              {/* {isFancy &&  <span
                    id="fancyBetIcon"
                    className="game-fancy"
                    style={{ display: "none" }}
                  >
                    Fancy
                  </span>} */}
                              {isbookmaker && <span
                                  id="bookMakerIcon"
                                  className="game-bookmaker"
                                  style={{display: "flex"}}
                              >
                    BookMaker
                  </span>}
                              <span
                                  id="feedingSiteIcon"
                                  className="game-sportsbook"
                                  style={{display: "none"}}
                              >
                    Sportsbook
                  </span>
                              <span
                                  id="sportsBookIcon_1"
                                  className="game-sportsbook"
                                  style={{display: "none"}}
                              >
                    Premium Tennis
                  </span>
                              {moment() >= moment(item.marketStartTime) && <span
                                  id="sportsBookIcon_2"
                                  className="game-sportsbook"
                                  // style={{ display: "flex" }}
                              >
                    Premium Cricket
                  </span>}
                              {moment() < moment(item.marketStartTime) && <span id="dateTimeInfo" className="time">
                    {matchTime}
                    
                  </span>}
                              {moment() >= moment(item.marketStartTime) &&
                                  <span id="dateTimeInfo" _ngcontent-njs-c67="" className="time">In-Play</span>}


                          </dt>
                          <dd id="eventName"> {item.eventName}</dd>
                      </dl>
                  </Link>
                  <a id={MKPINSC + id} className="pin-off"
                     onClick={() => Multimarketpin(id, item.eventId)}></a>
              </li>

              // <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showColor ? "-on" : "-off"}`}>
            //   <Link _ngcontent-njs-c67="" to={`/exchange/displaymatch/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
            //     <dl>
            //       <dt>
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
            //         {isbookmaker &&
            //           <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
            //         {moment() >= moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium soccer</span>}
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-Play {elapsTime}</span>}
            //         {moment() < moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
            //       </dt>

            //       {/* <span class="in_play">1 - 0</span> */}
            //       <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            //     </dl>
            //   </Link>
            //   <a _ngcontent-njs-c67=""  class="pin-off" ></a>
            // </li>
          )
        })}
    </>}
    </React.Fragment>
  )
}
