import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import AgeRestrictionDisclaimer from "./AgeRestrictionDisclaimer";

toast.configure();

export default function Mobilefirstlogin(props) {
  const [Newpassword, setNewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [password, setpassword] = useState("");
  const [agerest, setagerest] = useState(props.firstlogin);

  const ChangePassword = (e) => {
    e.preventDefault();

    var demoid1 = 'demo01';
    //var demoid2 = 'demo02'
    if (props.userid.toUpperCase() === demoid1.toUpperCase()) {
      document.getElementById("errorMsg").innerHTML =
        "Demo account password can't be changed";
      return;
    }

    var strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
    var test = Newpassword.match(strongRegex);

    if (Newpassword === "" || confirmpassword === "" || password === "") {
      document.getElementById("errorMsg").innerHTML =
        "Password can not be Blank!";
    } else if (Newpassword != confirmpassword) {
      document.getElementById("errorMsg").innerHTML =
        "password is not matched!";
    } else if (Newpassword === password) {
      document.getElementById("errorMsg").innerHTML =
        "Old password and new password is not same!";
    } else if (!test) {
      document.getElementById("errorMsg").innerHTML =
        "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)"
    } else {
      axios
        .post("https://liveapi247.live/api1/changepassword", {
          id: props.userid,
          //token:props.utoken,
          newPassword: Newpassword,
          oldPassword: password,
        })
        .then((result) => {
          if (result.status === 210) {
            document.getElementById("errorMsg").innerHTML =
              "Password Changed Successfully";

            setagerest(2);
          }
          if (result.status === 220) {
            document.getElementById("errorMsg").innerHTML =
              "Your old password is wrong";
          }
        })
        .catch((e) => {
          //setIsError(true);
        });
    }
  };

  return (
    <React.Fragment>
      {agerest == 1 && (
        <React.Fragment>
          <body _ngcontent-njs-c67=""
            style={{
              color: "#222",
              background: "linear-gradient(90deg,#d0021b,#ffea21)",

              minHeight: "100vh",
              backgroundAttachment: "fixed",
            }} className=" bg_9777"
          >
            <header _ngcontent-njs-c67=""
              class="pass-head"
              style={{
                backgroundImage:
                  "linear-gradient(#000 0%, #000 100%)",
                height: "21.33333vw",
                backgroundColor: "#2a1f3a",
              }}
            >
              <h1>fastbat</h1>
            </header>
            <div _ngcontent-njs-c67="" class="pass-wrap">
              <dl _ngcontent-njs-c67="" class="form-login">
                <form _ngcontent-njs-c67="" name="passwordPinForm" onsubmit="return false;">
                  <dt _ngcontent-njs-c67="" >Change Password</dt>
                  <dd>
                    <input _ngcontent-njs-c67=""
                      id="newPwd"
                      value={Newpassword}
                      onChange={(e) => {
                        setNewpassword(e.target.value);
                      }}
                      type="password"
                      placeholder="New Password"
                    />
                  </dd>
                  <dd>
                    <input _ngcontent-njs-c67=""
                      id="confirmPwd"
                      value={confirmpassword}
                      onChange={(e) => {
                        setconfirmpassword(e.target.value);
                      }}
                      type="password"
                      placeholder="New Password Confirm"
                    />
                  </dd>
                  <dd>
                    <input _ngcontent-njs-c67=""
                      id="currentPwd"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      type="password"
                      placeholder="Old Password"
                    />
                  </dd>
                  <dd>
                    <a _ngcontent-njs-c67=""
                      id="submit"
                      onClick={(e) => {
                        ChangePassword(e);
                      }}
                      class="btn-send"
                    >
                      Change
                    </a>
                  </dd>
                  <dd _ngcontent-njs-c67=""
                    id="errorMsg"
                    class="error"
                    style={{ color: "rgb(34, 34, 34)" }}
                  ></dd>
                </form>
              </dl>

              <div _ngcontent-njs-c67="" class="txt-wrap">
                <ul>
                  <li _ngcontent-njs-c67="" >
                    Password must have 8 to 15 alphanumeric without white space
                  </li>
                  <li _ngcontent-njs-c67="" >Password cannot be the same as username / nickname</li>
                  <li _ngcontent-njs-c67="" >
                    Must contain at least 1 capital letter, 1 small letter and 1
                    number
                  </li>
                  <li _ngcontent-njs-c67="" >
                    Password must not contain any special characters
                    (!,@,#,etc..)
                  </li>
                </ul>
              </div>
            </div>
          </body>
        </React.Fragment>
      )}
      {agerest == 2 && (
        <AgeRestrictionDisclaimer
          firstlogin={props.firstlogin}
          password={props.password}
          Newpassword={Newpassword}
        />
      )}
    </React.Fragment>
  );
}
